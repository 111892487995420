<template>
  <!-- 课件库 -->
  <div class="class_library">
    <van-cell-group>

      <van-cell :title="item.name" is-link @click="showChild" class="main_project">
        <template #right-icon>
          <van-icon v-if="show" name="arrow-down" size="20px"/>
          <van-icon v-else name="arrow-up" size="20px"/>
        </template>
      </van-cell>
      <div v-if="show" v-for="(item,index) in subProjects" :key="index">
        <van-cell :title="item.name" value="" @click="toSelectCase(item.id)" class="sub_project"/>
      </div>
    </van-cell-group>
  </div>
</template>

<script>
import Vue from 'vue';
import { Cell, CellGroup} from 'vant';
import {
        mapState,
    } from 'vuex'

Vue.use(Cell);
Vue.use(CellGroup);
export default {
  props:['item'],
  data(){
    return {
      show: false,
      subProjects:[],
    }
  },
  computed: {
    ...mapState(['basicInformation']),
  },
  mounted() {
    this.init();
  },
  methods:{
    showChild() {
        this.show = !this.show
    },
    init() {
      let subProjects = this.basicInformation.subprojects
      for(let sub of subProjects){
        if(this.item.id == sub.project_id) {
          this.subProjects.push(sub)
        }
      }
    },
    toSelectCase(id) {
      this.$router.push({
        name:'SelectCase',
        params:{
          subproject_id:id,
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.class_library{
  .van-cell{
    padding: 15px 16px;
    .van-cell__title{
      display: flex;
      // font-size: 16px; 
    }
  }
  .main_project{
    font-size: 16px;
  }
  .sub_project{
    font-size: 14px;
  }
  .confirm_btn{
    width: 90%;
    height: 43px;
    font-size: 20px;
    font-family: PingFang SC;
    color: #fff;
  }
}
</style>