<template>
  <!-- 课件库 -->
  <div class="class_library">
    <div v-for="(item,index) in projects">
      <Class :item="item"></Class>
    </div>
    <div class="btn">
        <van-button round type="info" size="large" class="confirm_btn" @click="Confirm()">确认</van-button>
    </div>
  </div>
  
</template>

<script>
import Class from './components/classList.vue';
import {
        mapState,
    } from 'vuex'
export default {
  components: { Class },
  data(){
    return {
      projects:[],
    }
  },
  computed: {
    ...mapState(['basicInformation']),
  },
  mounted() {
    this.init()
  },
  methods:{
    // 点击确认按钮
    Confirm(){
      this.$router.push({
        path:"choose_template"
      })
    },
    // 初始化
    async init() {
      this.projects = this.basicInformation.projects
    },
  }

}
</script>

<style lang="scss" scoped>
.class_library{
 
  .confirm_btn{
    width: 90%;
    height: 43px;
    font-size: 20px;
    font-family: PingFang SC;
    color: #fff;
  }
}
</style>